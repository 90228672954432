import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Index from './components/Index';
import Dashboard from './components/Dashboard';
import Legal from './components/Legal';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';


const config = {
  apiKey: "AIzaSyCrg6JDhN6J416IL-pK0ySX7FTdLtJ9c9o",
  authDomain: "eqwityapp.firebaseapp.com",
  databaseURL: "https://eqwityapp.firebaseio.com",
  projectId: "eqwityapp",
  storageBucket: "eqwityapp.appspot.com",
  messagingSenderId: "946828859324",
  appId: "1:946828859324:web:c887f9d2f876c662"
};

firebase.initializeApp(config);

let AUTH = firebase.auth();
let DB = firebase.firestore();
let STORAGE = firebase.storage().ref();


export default class App extends Component {
  constructor() {
    super();

    this.state = {
      scene: "index",
      message: null
    };

    this.authorize = this.authorize.bind(this);
    this.unauthorize = this.unauthorize.bind(this);
    this.changeScene = this.changeScene.bind(this);
  }

  authorize(type, email, password, company_name = null, industry = null, contact_person = null, website = null, phone = null) {
    this.setState({loading: true});
    if (!email || !password) {
      this.setState({loading: false, message: "All form fields required.", snackbarStyle: {backgroundColor: 'red'}});
      setTimeout(() => {this.setState({message: null})}, 3000)
    } else {
      
      if (type === 'log_in') {
        AUTH.signInWithEmailAndPassword(email, password)
      
        .then((userObject) => {
          let currentUserId = userObject.user.uid;
          
          DB.collection("startups").doc(currentUserId).get()

            .then((userDoc) => {
              let startup = userDoc.data();
              if (startup.type === "startup") {
                this.setState({loading: false, scene: "dashboard", startup: startup});
              } else {
                AUTH.signOut()
                .then(() => {
                  this.setState({loading: false, message: "Wrong account type. Please create a startup account.", snackbarStyle: {backgroundColor: 'red'}});
                  setTimeout(() => {this.setState({message: null})}, 3000);
                });
              }
            })

            .catch((error) => {
              this.setState({loading: false, message: `Not a startup account... ${error.message}`, snackbarStyle: {backgroundColor: 'red'}});
              setTimeout(() => {this.setState({message: null})}, 3000)
            })
        })
        
        .catch((error) => {
          this.setState({loading: false, message: error.message});
          setTimeout(() => {this.setState({message: null})}, 3000)
        });
      } else if (type === "registration") {
        AUTH.createUserWithEmailAndPassword(email, password)
      
        .then((userObject) => {
          if (!company_name || !industry || !contact_person || !website || !phone) {
            this.setState({loading: false, message: "All form fields required.", snackbarStyle: {backgroundColor: 'red'}});
            setTimeout(() => {this.setState({message: null})}, 3000)
          }
          let currentUserId = userObject.user.uid;
          let startupData = {
            active: false,
            disabled: true,
            id: currentUserId,
            name: company_name,
            industry: industry,
            contact_person: contact_person,
            website: website,
            phone: phone,
            votes: 0,
            ranking: 0,
            logo: "",
            short_description: "",
            long_description: "",
            type: "startup"
          };
          
          DB.collection("startups").doc(currentUserId).set(startupData).then(() => {

            if (startupData.type) {
                this.setState({loading: false, startup: startupData, scene: "dashboard"});
              } else {
                AUTH.signOut()
                .then(() => {
                  this.setState({loading: false, message: "Wrong account type. Please create a startup account.", snackbarStyle: {backgroundColor: 'red'}});
                  setTimeout(() => {this.setState({message: null})}, 3000);
                });
              }
            })

            .catch((error) => {
              this.setState({loading: false, message: error.message});
              setTimeout(() => {this.setState({message: null, snackbarBackgroundColor: 'red'})}, 3000)
            })
        })
        
        .catch((error) => {
          this.setState({loading: false, message: error.message});
          setTimeout(() => {this.setState({message: null, snackbarBackgroundColor: 'red'})}, 3000)
        });
      }
    }
  }

  unauthorize() {
    AUTH.signOut()
    .then(() => {
      this.setState({scene: "index", message: "You've been logged out successfully.", snackbarStyle: {backgroundColor: 'blue'}});
      setTimeout(() => {this.setState({message: null})}, 3000);
    });
  }

  changeScene(scene) {
    this.setState({scene});
  }

  render() {
    return (
      <div style={{minHeight: '100vh', background: '#f5f5f5'}}>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={this.state.message !== null}
          onClose={() => this.setState({message: null})}
          ContentProps={{
            'aria-describedby': 'message-id',
            root: {
              backgroundColor: this.state.snackbarBackgroundColor
            }
          }}
        >
          <SnackbarContent
            style={this.state.snackbarStyle}
            message={this.state.message}
          />
        </Snackbar>
        {this.state.scene === "index" && <Index loading={this.state.loading} firebase={firebase} AUTH={AUTH} DB={DB} authorize={this.authorize} changeScene={this.changeScene}/>}
        {this.state.scene === "dashboard" && <Dashboard startup={this.state.startup} DB={DB} STORAGE={STORAGE} changeScene={this.changeScene} unauthorize={this.unauthorize}/>}
        {this.state.scene === "legal" && <Legal changeScene={this.changeScene}/>}
      </div>
    );
  }
}
