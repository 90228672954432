import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CSS from './../css/Styles';

function DashCard(props) {

    const { classes } = props;

    return(
        <Grid item xs={12} sm={6} md={props.width ? props.width : 6}>
            <Card style={{margin: '2rem', minHeight: '15rem'}}>
                <CardContent style={{textAlign: 'center'}}>
                    {props.isDataCard ? <Typography varient="h1" className={classes.dashCardData}>{props.data}</Typography> : null}
                    {!props.isDataCard ? <span onClick={props.action ? () => props.action() : null} style={{cursor: 'pointer'}}>{props.icon}</span> : null}
                    <Typography variant="h1" className={classes.dashCardDescription}>
                        {props.title}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default withStyles(CSS)(DashCard);