import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CSS from './../css/Styles';
import { withStyles } from '@material-ui/core/styles';
import WordmarkWhite from './../assets/logo-wordmark-horizontal-white.png';
import LogoLight from './../assets/logo-wordmark-white.png';
import $ from 'jquery'; 

class Legal extends React.Component {

    componentWillMount() {
        $("html, body").scrollTop(0);
    }

    render() {
        const { classes } = this.props;

        return(
            <React.Fragment>
                <Grid 
                    container spacing={24} 
                    style={{
                        minHeight: '100vh', 
                        padding: 25,
                        backgroundColor: '#f5f5f5'
                    }}
                >
                    <AppBar className={classes.navigation} style={{backgroundColor: '#000'}}>
                        <Grid container style={{overflowX: 'hidden', maxWidth: '1600px', alignSelf: 'center',}}>
                            <Grid item md={1}/>
                            <Grid item xs={12} md={10}>
                                <div className={classes.desktop}>
                                    <Toolbar style={{padding: 0, alignItems: 'center', color: '#000'}}>
                                        <img onClick={() => this.props.changeScene("index")} className={classes.logo} style={{cursor: 'pointer'}} src={WordmarkWhite} alt="eqwity logo"/>
                                        <div style={{flexGrow: 1}}></div>
                                        <Button onClick={() => this.props.changeScene("index")} style={{textTransform: 'none', color: '#fff'}}>Home</Button>
                                    </Toolbar>
                                </div>
                                <div className={classes.mobile} style={{position: 'relative', textAlign: 'center', padding: '0.5rem'}}>
                                    <BackIcon onClick={() => this.props.changeScene("index")} style={{zIndex: 999, position: 'absolute', top: '2rem', left: '1rem', fontSize: '2rem', cursor: 'pointer', color: '#777'}}/>
                                    <img onClick={() => this.props.changeScene("index")} className={classes.logo} style={{cursor: 'pointer'}} src={LogoLight} alt="eqwity logo"/>
                                </div>
                            </Grid>
                            <Grid item md={1}/>
                        </Grid>
                    </AppBar>
                    <Grid item sm={1} md={1}/>
                    <Grid item sm={10} md={10} style={{paddingTop: '5rem'}}> 
                        <Paper style={{padding: 24}}>
                            <p>
                                Eqwity, Inc ("us", "we", or "our") operates the eqwity.com website, Eqwity app, "eqwity" points,  and widget(s) (the "Service").
                                This page informs you of our terms and policies regarding the collection, usef and disclosure of Personal Information when you use our Service.
                                We will not use or share your information with anyone except as described in this Privacy Policy.
                                We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. 
                            </p>
    
                            <div style={{textAlign: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                                <Typography variant="headline">Terms of Use</Typography>
                            </div>
    
                            <p>
                                By accessing the website at http://eqwity.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, 
                                and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited 
                                from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
                            </p>
    
                            <h4>Use License</h4>
                            <div>
                                Permission is granted to temporarily download one copy of the materials (information or software) on Eqwity, Inc's website for personal, non-commercial transitory viewing only. 
                                This is the grant of a license, not a transfer of title, and under this license you may not:
                                <ul>
                                    <li>modify or copy the materials;</li>
                                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li>attempt to decompile or reverse engineer any software contained on Eqwity, Inc's website;</li>
                                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>
                                This license shall automatically terminate if you violate any of these restrictions and may be terminated by Eqwity, Inc at any time. 
                                Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                            </div>
    
                            <h4>Disclaimer</h4>
                            <p>
                                We collect information that your browser sends whenever you visit our Service ("Log Data"). 
                                This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, 
                                browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                            </p>

                            <h4>"Eqwity Points"</h4>
                            <p>
                                While our hope is to provide our community with financial returns, we do not guarantee them. Points are 
                                awarded for participation, not an exchange for money. Eqwity points are not a security. They do not guarantee a share in any investment and do not have monetary value. 
                                Exchanging money or items for eqwity points is at the discretion of Eqwity and is not mandatory.
                            </p>
    
                            <h4>Limitations</h4>
                            <p>
                                In no event shall Eqwity, Inc or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) 
                                arising out of the use or inability to use the materials on Eqwity, Inc's website, even if Eqwity, Inc or a Eqwity, Inc authorized representative has been notified orally or 
                                in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, 
                                these limitations may not apply to you.
                            </p>
    
                            <h4>Accuracy</h4>
                            <p>
                                The materials appearing on Eqwity, Inc's website could include technical, typographical, or photographic errors. 
                                Eqwity, Inc does not warrant that any of the materials on its website are accurate, complete or current. 
                                Eqwity, Inc may make changes to the materials contained on its website at any time without notice. 
                                However Eqwity, Inc does not make any commitment to update the materials.
                            </p>
    
                            <h4>Links</h4>
                            <p>
                                Eqwity, Inc has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. 
                                The inclusion of any link does not imply endorsement by Eqwity, Inc of the site. Use of any such linked website is at the user's own risk.
                            </p>
    
                            <h4>Modifications</h4>
                            <p>
                                Eqwity, Inc may revise these terms of service or privacy policy for its website, apps, widgets, and et cetera at any time without notice. 
                                By using this website you are agreeing to be bound by the then current version of these terms of service.
                            </p>
    
                            <h4>Governing Law</h4>
                            <p>
                                These terms and conditions are governed by and construed in accordance with the laws of Deleware and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                            </p>
    
                            <div style={{textAlign: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                                <Typography variant="headline">Privacy Policy</Typography>
                            </div>
    
                            <h4>Information Collection And Use</h4>
                            <p>
                                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. 
                                Personally identifiable information may include, but is not limited to, your name, phone number, other information ("Personal Information").]
                            </p>
    
                            <h4>Log Data</h4>
                            <p>
                                We collect information that your browser sends whenever you visit our Service ("Log Data"). 
                                This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, 
                                browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                            </p>
    
                            <h4>Cookies</h4>
                            <p>
                                Cookies are files with small amount of data, which may include an anonymous unique identifier. 
                                Cookies are sent to your browser from a web site and stored on your computer's hard drive.
                                We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
                                However, if you do not accept cookies, you may not be able to use some portions of our Service.
                            </p>
    
                            <h4>Security</h4>
                            <p>
                                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. 
                                While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                            </p>
    
                            <h4>Age Restriction + Privacy</h4>
                            <p>
                                Our Service is not for anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from children under 18. 
                                If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. 
                                If we discover that a Children under 18 has provided us with Personal Information, we will delete such information from our servers immediately.
                            </p>

                            <h4>Apple</h4>
                            <p>
                                Apple, the Apple logo, iPhone, and iPad are trademarks of Apple Inc., registered in the U.S. and other countries and regions. App Store is a service mark of Apple Inc.
                            </p>

                            <h4>Google</h4>
                            <p>
                                Google Play and the Google Play logo are trademarks of Google LLC.
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item sm={1} md={1}/>
                </Grid>
            </React.Fragment>
        )
    }
        
}

export default withStyles(CSS)(Legal)