import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import DashCard from './DashCard';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LogoLight from './../assets/logo-white.png';
import { withStyles } from '@material-ui/core/styles';
import CSS from './../css/Styles';
import PersonIcon from '@material-ui/icons/Person';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import CustomersIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import $ from 'jquery'; 


class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            message: null,
            snackbarStyle: {backgroundColor: 'blue'},
            scene: "log_in",
            timePeriodMenuVisible: false,
            accountMenuVisible: false,
            profileDetailsFormVisible: false,
            anchorEl: null,
            companyName: "",
            industry: "",
            contactPerson: "",
            revenueShare: "",
            website: props.startup.website,
            pitchVideo: props.startup.pitch_video,
            logo: "",
            logoUrl: props.startup.logo,
            shortDescription: props.startup.short_description,
            longDescription: props.startup.long_description,
            active: props.startup.active,
            votes: props.startup.votes,
            ranking: props.startup.ranking,
        }

        this.toggleTimePeriodMenuVisibility = this.toggleTimePeriodMenuVisibility.bind(this);
        this.toggleAccountMenuVisibility = this.toggleAccountMenuVisibility.bind(this);
        this.toggleProfileDetailsForm = this.toggleProfileDetailsForm.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);
        this.addImage = this.addImage.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    componentDidMount() {
        $("html, body").scrollTop(0);
    }

    toggleTimePeriodMenuVisibility(event) {
        this.setState({
            timePeriodMenuVisible: !this.state.timePeriodMenuVisible,
            anchorEl: (this.state.timePeriodMenuVisible ? null : event.currentTarget)
        });
    }

    toggleAccountMenuVisibility(event) {
        this.setState({
            accountMenuVisible: !this.state.accountMenuVisible,
            anchorEl: (this.state.accountMenuVisible ? null : event.currentTarget)
        });
    }

    toggleStatus() {
        if (this.state.shortDescription === "" || this.state.longDescription === "" || this.state.pitchVideo === "" || this.state.logoUrl === "") {
            this.setState({message: "Please complete your profile before activing your account", snackbarStyle: {backgroundColor: 'red'}});
            setTimeout(() => {this.setState({message: null})}, 4000);  
            return;
        }
        let startupRef = this.props.DB.collection("startups").doc(this.props.startup.id);
        let updatedStatus = !this.state.active;

        startupRef.update({
            active: updatedStatus
        }).then(() => {
            this.setState({message: "Status updated successfully!", snackbarStyle: {backgroundColor: 'green'}});
            setTimeout(() => {this.setState({message: null})}, 2000);
            this.setState({active: updatedStatus});
        }).catch((error) => {
            this.setState({message: error.message, snackbarStyle: {backgroundColor: 'red'}});
            setTimeout(() => {this.setState({message: null})}, 2000);           
        })
    }

    toggleProfileDetailsForm() {
        this.setState({profileDetailsFormVisible: !this.state.profileDetailsFormVisible});
    }

    addImage(event) {
        this.setState({loading: true});

        let file = event.target.files[0];

        this.setState({logo: file, loading: false});
    }

    updateProfile() {
        let website = this.state.website.trim();
        let pitchVideo = this.state.pitchVideo.trim();
        let logo = this.state.logo;
        let shortDescription = this.state.shortDescription;
        let longDescription = this.state.longDescription;

        if (!website || !pitchVideo || !shortDescription || !longDescription) {
            this.setState({message: "All profile details are required", snackbarStyle: {backgroundColor: 'red'}});
            setTimeout(() => {this.setState({message: null})}, 2000)
        } else {
            this.setState({loading: true});
            let startupRef = this.props.DB.collection("startups").doc(this.props.startup.id);

            startupRef.update({
                website: website,
                pitch_video: pitchVideo,
                short_description: shortDescription,
                long_description: longDescription
            }).then(() => {
                if(this.state.logo) {
                    let logoRef = this.props.STORAGE.child(`logos/${this.props.startup.id}`);

                    logoRef.put(logo).then((logoSnapshot) => {
                        logoSnapshot.ref.getDownloadURL().then((URL) => {
                            startupRef.update({
                                logo: URL
                            }).then(() => {
                                this.setState({
                                    loading: false, 
                                    message: "Profile updated successfully", 
                                    snackbarStyle: {backgroundColor: 'green'},
                                    website: website,
                                    pitch_video: pitchVideo,
                                    short_description: shortDescription,
                                    long_description: longDescription,
                                    logoUrl: URL
                                });
                                setTimeout(() => {this.setState({message: null})}, 2000);
                                this.toggleProfileDetailsForm();
                            }).catch((error) => {
                                this.setState({loading: false, message: error.message, snackbarStyle: {backgroundColor: 'red'}});
                                setTimeout(() => {this.setState({message: null})}, 2000);
                            })
                        }) 
                    }).catch((error) => {
                        this.setState({loading: false, message: error.message, snackbarStyle: {backgroundColor: 'red'}});
                        setTimeout(() => {this.setState({message: null})}, 2000);
                    })
                } else {
                    this.setState({loading: false, message: "Profile updated successfully", snackbarStyle: {backgroundColor: 'green'}});
                    setTimeout(() => {this.setState({message: null})}, 2000);
                    this.toggleProfileDetailsForm();
                }
            }).catch((error) => {
                this.setState({loading: false, message: error.message, snackbarStyle: {backgroundColor: 'red'}});
                setTimeout(() => {this.setState({message: null})}, 2000);
            })
        }
    }
    
    changeScene(scene) {
        this.setState({scene});
    }


    render() {
        const { classes } = this.props;
    
        return(
            <React.Fragment>
                <div>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={this.state.message !== null}
                        onClose={() => this.setState({message: null})}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                    >
                        <SnackbarContent
                            style={this.state.snackbarStyle}
                            message={this.state.message}
                        />
                    </Snackbar>
                    <AppBar position="relative" className={classes.navigation}>
                        <Grid container style={{overflow: 'hidden', maxWidth: '1600px', alignSelf: 'center'}}>
                            <Grid item md={1}/>
                            <Grid item xs={12} md={10}>
                                <div className={classes.desktop}>
                                    <Toolbar style={{padding: 0, alignItems: 'center'}}>
                                        <img onClick={() => this.changeScene("landing")} className={classes.dashboardLogo} style={{cursor: 'pointer'}} src={LogoLight} alt="eqwity logo"/>
                                        <Typography variant="subtitle1" style={{flexGrow: 1}}>{this.props.startup.name}</Typography>
                                        <Button onClick={() => this.props.unauthorize()} variant="outlined" style={{color: '#ccc', borderColor: '#ccc', textTransform: 'none'}}>
                                            log out
                                        </Button>
                                    </Toolbar>
                                </div>
                                <div className={classes.mobile} style={{position: 'relative', textAlign: 'center', padding: '0.5rem'}}>
                                    <img onClick={() => this.changeScene("landing")} className={classes.dashboardLogo} style={{cursor: 'pointer'}} src={LogoLight} alt="eqwity logo"/>
                                    {this.state.scene !== "menu" && <MenuIcon onClick={() => this.changeScene("menu")} style={{zIndex: 999, position: 'absolute', top: '2rem', right: '1rem', fontSize: '2rem', cursor: 'pointer', color: '#777'}}/>}
                                </div>
                            </Grid>
                            <Grid item md={1}/>
                        </Grid>
                    </AppBar>
                    <Grid alignItems="center" container>
                        <DashCard
                            icon={this.state.active ? <ToggleOnIcon className={classes.dashboardIcon} style={{color: 'lightgreen'}}/> : <ToggleOffIcon className={classes.dashboardIcon} style={{color: 'red'}}/>}
                            data={this.state.status}
                            action={this.toggleStatus}
                            title="Fundraising Status"
                        />
                        <DashCard
                            icon={<PersonIcon className={classes.dashboardIcon}/>}
                            title="Edit Profile"
                            action={this.toggleProfileDetailsForm}
                        />
                        <DashCard
                            icon={<CustomersIcon className={classes.dashboardIcon}/>}
                            isDataCard={true}
                            data={this.state.votes}
                            title="Votes"
                        />
                        <DashCard
                            icon={<CustomersIcon className={classes.dashboardIcon}/>}
                            isDataCard={true}
                            data={this.state.votes === 0 ? 'N/A' : this.state.ranking}
                            title="Ranking"
                        />
                        {/*<DashCard
                            icon={<MarketingIcon className={classes.dashboardIcon}/>}
                            title="Settings"
                            width={12}
                            description="$1,054"
                            primaryButtonTitle="Pay"
                            primaryScene="new_push_notification"
                            secondaryButtonTitle="Set Revenue Share"
                            secondaryScene="sent_push_notifications"
                        />*/}
                    </Grid>
                    <Dialog
                        open={this.state.profileDetailsFormVisible}
                        onClose={() => this.toggleProfileDetailsForm()}
                    >
                        <DialogTitle>Profile Details</DialogTitle>
                        {this.state.loading && <LinearProgress style={{margin: '1rem'}}/>}
                        <DialogContent>
                            <TextField
                                disabled={this.state.loading}
                                variant="outlined"
                                margin="dense"
                                label="Website URL"
                                value={this.state.website}
                                style={{marginBottom: '2rem'}}
                                helperText="https://www.example.com"
                                onChange={(event) => this.setState({website: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                disabled={this.state.loading}
                                variant="outlined"
                                margin="dense"
                                label="Pitch Video URL"
                                value={this.state.pitchVideo}
                                style={{marginBottom: '2rem'}}
                                helperText="YouTube or Vimeo please"
                                onChange={(event) => this.setState({pitchVideo: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                disabled={this.state.loading}
                                multiline
                                fullWidth
                                rows={2}
                                variant="outlined"
                                margin="dense"
                                label="Short Description"
                                value={this.state.shortDescription}
                                style={{marginBottom: '2rem'}}
                                onChange={(event) => this.setState({shortDescription: event.target.value})}
                                inputProps={{
                                    maxLength: 100
                                }}
                                helperText={`${100 - this.state.shortDescription.length} characters remaining`}
                            />
                            <TextField
                                disabled={this.state.loading}
                                variant="outlined"
                                margin="dense"
                                label="Long Description"
                                fullWidth
                                multiline
                                rows="4"
                                value={this.state.longDescription}
                                style={{marginBottom: '2rem'}}
                                onChange={(event) => this.setState({longDescription: event.target.value})}
                                inputProps={{
                                    maxLength: 300
                                }}
                                helperText={`${300 - this.state.longDescription.length} characters remaining`}
                            />
                            <TextField
                                disabled={this.state.loading}
                                variant="outlined"
                                margin="dense"
                                type="file"
                                helperText="High-Res Logo (PNG or JPG)"
                                onChange={this.addImage}
                                style={{marginBottom: '2rem'}}
                                fullWidth
                            />
                            <DialogActions>
                            <Button onClick={() => this.toggleProfileDetailsForm()} style={{textTransform: 'none', backgroundColor: '#ccc', color: '#222'}}>cancel</Button>
                                <Button onClick={() => this.updateProfile()} style={{textTransform: 'none', backgroundColor: '#000', color: '#fff'}}>update</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
            </React.Fragment>
        );
    }

}

export default withStyles(CSS)(Dashboard);